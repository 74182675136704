import { __assign, __awaiter, __generator } from "tslib";
import "./index.scss";
import { defineComponent, onMounted, reactive, ref } from "vue";
import HomeJumbotron from "@/views/home/homeJumbotron/index.vue";
import { useRoute } from "vue-router";
import { getBrochureTypeApi, getBrochureListByTypeApi, getBrochureListApi, } from "@/api/downloadBrochure";
import BasisGroup from "@/views/basisGroup/index.vue";
import Nav from "@/components/nav/nav.vue";
export default defineComponent({
    components: {
        Nav: Nav,
        BasisGroup: BasisGroup,
        HomeJumbotron: HomeJumbotron
    },
    setup: function () {
        var _this = this;
        var route = useRoute();
        document.documentElement.scrollTop = 0;
        var brochureTypeList = ref("");
        var brochureList = ref("");
        var productTypeId = ref("");
        var navActiveIndex = ref("5");
        //获取下载手册类型列表
        var getBrochureTypeList = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = brochureTypeList;
                        return [4 /*yield*/, getBrochureTypeApi()];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        //获取下载手册列表
        var getBrochureList = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = brochureList;
                        return [4 /*yield*/, getBrochureListApi()];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        //根据类型获取下载手册列表
        var getBrochureListByType = function () { return __awaiter(_this, void 0, void 0, function () {
            var _a;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        _a = productTypeId;
                        return [4 /*yield*/, getBrochureListByTypeApi({
                                productTypeId: productTypeId.value,
                            })];
                    case 1:
                        _a.value = _b.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var productId = route.query.id;
        var productName = route.query.name;
        var imgSrc = route.params.img;
        productId = parseInt(productId);
        console.log("productId:" + productId);
        var imgSrcList = reactive({
            imgItems: [],
        });
        onMounted(function () {
            getBrochureTypeList();
            getBrochureList();
            getBrochureListByType();
        });
        var selectProduct = function (id) {
            //TODO
            console.log(productId);
        };
        return __assign(__assign({ productId: productId,
            productName: productName,
            imgSrc: imgSrc }, imgSrcList), { selectProduct: selectProduct,
            brochureList: brochureList,
            navActiveIndex: navActiveIndex,
            brochureTypeList: brochureTypeList });
    },
});
