import axios from "@/utils/request";
export const getBrochureListApi = () => {
  return axios({
    path: "/publicDownload/selectAll",
    method: "POST",
  })
}
export const getBrochureListByTypeApi = (data: any) => {
  return axios({
    path: "/publicDownload/listByProductTypeId",
    method: "POST",
    data
  })
}

export const getBrochureTypeApi = () => {
  return axios({
    path: "/publicDownloadType/selectAll",
    method: "POST",
  })
}

